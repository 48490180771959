.social-icons {
  display: flex;
  align-items: center;
  border-right: 1px solid #fff;
  padding-right: 20px;

  a {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 20px;
    text-decoration: none;
    transition: color .3s ease;

    &:hover {
      color: $color-highlight;
    }

    &:last-child {
      margin-right: 0;
    }

    span {
      display: none;
      font-size: 12px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  &--footer {
    border-right: 0;
    flex-wrap: wrap;
    justify-content: center;

    a {
      color: $color-primary;
      margin: 0 20px 15px;

      &:last-child {
        margin-right: 20px;
      }

      &:hover {
        color: $color-highlight-tertiary;
      }

      i {
        font-size: 12px;
        position: relative;
        top: -1px;
      }

      span {
        display: block;
        letter-spacing: 1px;
      }
    }
  }
}