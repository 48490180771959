.footer {
  padding: 39px 0 44px;
  background-color: $border-color;
  border-top: 40px solid $color-highlight;

  .container {
    max-width: 85.41%;
    margin-left: 5.52%;
  }

  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      margin-left: 0;
    }
  }

  &__top {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid $border-color-secondary;

    &_box {
      min-width: 210px;
      padding-right: 10px;

      &:nth-child(1) {

        img {
          max-width: 123px;
          width: 90%;
          display: block;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 24px;

        li {
          font-size: 16px;
          line-height: 1.31;
          margin-bottom: 3px;

          a {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }

    }

    @media (max-width: 991px) {
      &_box {
        min-width: 1px;
        width: 25%;
      }
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;

      &_box {
        width: 50%;
        margin-bottom: 40px;

        ul:last-child {
          margin-bottom: 0; 
        }
      }
    }
  }

  &__bottom {
    padding-top: 38px;
    display: flex;
    justify-content: space-between;
    
    &_box {

      p, li {
        font-size: 10px;
        line-height: 1.4;
      }

      ul {
        list-style-type: none;
        padding: 0;
        display: flex;

        li {
          margin-left: 34px;

          a {
            color: $color-primary;
            text-decoration: none;
          }
        }
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;

      &_box {

        p {
          margin-bottom: 20px;
          text-align: center;
        }

        li:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &__middle {
    padding: 50px 0 0;
    display: flex;
    justify-content: center;
  }
}