.header {
  padding: 17px 0;
  background-color: $color-primary;

  .logo {
    width: 45px;
    height: 45px;
  }

  &__logo {

    img {
      height: 50px;
      display: block;

      @media (max-width: 400px) {
        height: 35px;
      }
    }
  }

  &__right {
    display: flex;

    .btn {
      margin-left: 20px;
    }
  }
}