// Colors
$color-primary: #181c3d;
$color-highlight: #fcd800;
$color-highlight-secondary: #3c54ee;
$color-highlight-tertiary: #58c3bb;

$color-secondary: #3b3d50;
$color-tertiary: #2e2e2e;
$input-color: #c4c4c4;

$border-color: #f1f1f1;
$border-color-secondary: #90939f;
$border-color-tertiary: #dfe3e9;

$color-error: red;

$info-bg: #f1f3f4;
$modal-bg: #6a6868;

// Widths
$container-width: 1150px;

// Fonts
$ff-primary: "sailec_regular";
$ff-header: "sailec_bold";
