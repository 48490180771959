.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 32px;
  background-color: $color-highlight;
  color: $color-primary;
  font-size: 12px;
  line-height: 1.34;
  text-decoration: none;
  min-width: 120px;
  border-radius: 16px;
  cursor: pointer;
  border: 0;
  outline: 0;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $color-highlight-tertiary;
  }

  &--text {
    background-color: transparent;
    padding: 0;
    height: auto;
    color: $color-highlight;
    font-weight: bold;
    min-width: 1px;
    border-radius: 0;
    transition: color 0.3s ease;

    &:hover {
      background-color: transparent;
      color: $color-highlight-tertiary;
    }
  }

  &--md {
    padding: 0 54px;
    border-radius: 30px;
    font-size: 16px;
    height: 48px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  &--md2 {
    height: 45px;
    border-radius: 7px;
    min-width: 186px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 40px;
    color: $color-tertiary;
  }

  &--md3 {
    height: 45px;
    border-radius: 7px;
    min-width: 186px;
    font-size: 16px;
    font-weight: bold;
    padding: 0 40px;
    color: white;
    background: linear-gradient(135deg, #575bde 25%, #393ed8 100%);
  }

  &--lg {
    min-width: 200px;
    font-size: 18px;
    height: 54px;
    border-radius: 30px;
  }

  &--light {
    background-color: $info-bg;

    &:hover {
      background-color: $input-color;
    }
  }
}
