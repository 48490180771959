.u-btn-wrapper {
  display: flex;
  justify-content: center;
}

.u-error-msg {
  color: $color-error;
  font-size: 14px;
}

.u-success-msg {
  color: $color-highlight-tertiary;
  font-size: 14px;
}