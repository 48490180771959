.c-score-end {
  flex: 1;
  padding: 0 46px;
  overflow: hidden;

  @media (max-width: 600px) {
    padding: 0 30px;
  }

  &__head {
    margin-bottom: 30px;

    h2 {
      font-size: 35px;
      line-height: 1.34;
      font-weight: bold;
      text-align: center;
    }

    @media (max-width: 767px) {
      h2 {
        font-size: 26px;
        padding: 0 30px;
      }
    }

    @media (max-width: 480px) {
      h2 {
        padding: 0;
      }
    }
  }

  &__body {
    margin-bottom: 23px;
    
    .gauge-wrap {
      max-width: 200px;
      margin: 0 auto 20px;

      > p {
        font-size: 14px;
        font-weight: bold;
        text-align: center;
      }
    }

    &_info {
      position: relative;
      padding: 24px 45px;
      text-align: center;
      border-radius: 10px;
      max-width: 530px;
      margin: 0 auto;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $info-bg;
        content: '';
        border-radius: 10px;
        z-index: 2;
      }

      &::after {
        content: '';
        background-image: url('../../img/info-pointer.svg');
        width: 35px;
        height: 34px;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        left: -20px;
        bottom: -10px;
      }

      p {
        font-weight: bold;
        position: relative;
        z-index: 3;
      }

      img {
        position: absolute;
        left: -120px;
        top: 60px;
        transform: rotate(-16deg);
      }

      @media (max-width: 991px) {
        img {
          max-width: 80px;
          left: -100px;
          top: 70px;
        }
      }

      @media (max-width: 767px) {
        img {
          max-width: 40px;
          left: -50px;
          top: auto;
          bottom: -30px;
        }
      }

      @media (max-width: 480px) {
        padding: 20px;
      }
    }
  }

  &__foot {
    position: relative;
    z-index: 3;

    &_social {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 32px;

      a {
        display: block;
        margin: 0 10px;
        transition: opacity .3s ease;

        &:hover {
          opacity: 0.8;
        }
      }

      @media (max-width: 480px) {
        a {
          width: 40px;
          height: 40px;
          margin: 0 5px;

          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}