.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 9999;
  
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($modal-bg, 0.43);
    opacity: 0;
    transition: opacity .6s;
  }

  &__box {
    max-width: 807px;
    width: 90%;
    background-color: #fff;
    margin: 155px auto 10px;
    border-radius: 22px;
    min-height: 575px;
    padding: 45px 0;
    position: relative;
    top: -100px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    opacity: 0;
    transition: top .4s ease, opacity .4s ease;

    &_close {
      position: absolute;
      cursor: pointer;
      top: 31px;
      right: 34px;
    }

    &_content {
      flex: 1;
      display: flex;
      height: auto;
    }

    @media (max-width: 991px) {
      margin: 50px auto 10px;
    }

    @media (max-width: 767px) {
      min-height: 500px;
    }

    @media (max-width: 600px) {
      padding: 30px 0;
    }

    @media (max-width: 480px) {
      margin: 10px auto 0;

      &_close {
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}