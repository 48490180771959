.c-score-start {
  padding: 0 46px;

  @media (max-width: 600px) {
    padding: 0 30px;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 66px;
    padding-top: 22px;

    img {
      display: block;
      max-width: 100%;
    }

    h2 {
      font-size: 57px;
      font-weight: bold;
      color: $color-tertiary;
      text-align: center;
      padding-right: 73px;
    }

    @media (max-width: 991px) {
      h2 {
        font-size: 44px;
      }
    }

    @media (max-width: 767px) {
      img {
        max-width: 70px;
        margin-right: 10px;
      }
      h2 {
        font-size: 32px;
        padding-right: 80px;
      }
    }

    @media (max-width: 600px) {
      img {
        max-width: 40px;
        margin-right: 10px;
      }
      h2 {
        font-size: 26px;
        padding-right: 50px;
      }
    }
  }

  &__body {
    max-width: 512px;
    margin: 0 auto 77px;
    text-align: center;

    p {
      line-height: 1.88;
      font-weight: bold;
    }
  }

  &__foot {
    display: flex;
    justify-content: center;
  }
}