.c-loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.c-loader:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid $color-highlight;
  border-color: $color-highlight transparent $color-highlight transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
