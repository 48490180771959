@font-face {
  font-family: "sailec_bold";
  src: url("../../fonts/SailecBold.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sailec_regular";
  src: url("../../fonts/SailecRegular.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "lucida_granderegular";
  src: url("../../fonts/lucidagrande.eot");
  src: url("../../fonts/lucidagrande.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/lucidagrande.woff2") format("woff2"),
    url("../../fonts/lucidagrande.woff") format("woff"),
    url("../../fonts/lucidagrande.svg#lucida_granderegular") format("svg");
  font-weight: normal;
  font-style: normal;
}
