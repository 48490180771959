.c-info {
  padding: 15px 0;
  font-size: 14px;

  &--success {
    color: $color-highlight-tertiary;
  }

  &--error {
    color: $color-error;
  }
}