.countdown {

  > div {
    padding: 10px;
    margin: 0 15px;
    border-radius: 3px;
    background: $color-primary;
    display: inline-block;
    color: #fff;
    font-size: 24px;
    text-align: center;

    span {
      padding: 15px;
      border-radius: 3px;
      background: $color-highlight-tertiary;
      display: inline-block;
    }

    .smalltext {
      padding-top: 5px;
      font-size: 16px;
    }

    @media (max-width: 480px) {
      margin: 0 5px;
      font-size: 20px;
      padding: 7px;

      span {
        padding: 10px;
      }

      .smalltext {
        font-size: 12px;
      }
    }
    
    @media (max-width: 319px) {
      margin: 0 2px;
      font-size: 16px;
      padding: 5px;

      span {
        padding: 8px;
      }

      .smalltext {
        font-size: 10px;
      }
    }
  }
}
