.s-intro {
  padding: 60px 0 198px;
  // padding: 0 15px;
  overflow: hidden;
  position: relative;

  &__box {
    // max-width: 550px;
    display: flex;
    width: 108%;

    h1 {
      font-size: 50px;
      margin-bottom: 37px;
      text-align: center;
    }

    p {
      margin-bottom: 30px;
      text-align: center;
    }
    &__text {
      margin-left: -5%;
      width: 48%;
    }
    &__roles {
      width: 48%;
      display: flex;

      h4 {
        padding-top: 30px;
      }
      p {
        padding: 10px;
      }
      &__subbox {
        background: #ffffff;
        text-align: center;
        margin: 1.5%;
        // border: 1px solid black;
        box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
        // box-shadow: inset 9px 9px 9px rgba(0, 0, 0, 0.04);
        padding-bottom: 15px;
      }
      &__entrepreneur {
        min-width: 317px;
        width: 48%;
        margin-right: 2.6%;
      }
      &__investor {
        width: 48%;
        min-width: 317px;
        margin-right: 2.6%;
      }
    }
  }

  .logo {
    position: absolute;
    width: 760px;
    max-width: 60%;
    bottom: -25.18%;
    right: -8.33%;
    z-index: -1;
  }

  @media (max-width: 991px) {
    padding: 100px 0 150px;
    .s-intro {
      &__box {
        // max-width: 550px;
        display: block;
        width: 100%;

        &__text {
          margin: 10px 0;
          width: 100%;
          margin-bottom: 25px;
        }
        &__roles {
          width: 108%;
          display: block;
          &__entrepreneur {
            width: 90%;
            margin-right: 2.6%;
          }
          &__investor {
            width: 90%;
            margin-right: 2.6%;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 80px 0 120px;

    &__textbox {
      margin: 0 auto;
      text-align: center;
    }
  }
}
