.s-insights {
  background-image: url('../../img/bg-pattern.svg');
  background-size: cover;
  background-position: center top;
  padding-bottom: 52px;

  &__box {
    max-width: 902px;
    margin: 0 auto;
    padding: 45px 30px 20px;
    background-color: #fff;
    box-shadow: -20px 20px 35px rgba(0,0,0,0.2);
    text-align: center;
    position: relative;
    top: -84px;

    h2 {
      max-width: 500px;
      margin: 0 auto 30px;
    }

    &_image-boxes {
      display: flex;
      margin-top: 32px;
    }
  }

  @media (max-width: 600px) {
    padding-bottom: 0px;

    &__box {
      padding: 20px 10px 10px;

      h2 {
        margin-bottom: 15px;
      }

      &_image-boxes {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 480px) {
    &__box {

      &_image-boxes {
        display: block;
      }
    }
  }
}