.gauge {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;

  p {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: bold;
  }

  svg {
    width: 100%;
    height: 100%;
    transform: rotate(135deg);

    .needle-1,
    .low,
    .average,
    .high {
      transform-origin: center;
    }
  }
}

.gauge2 {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;

  img.gauge {
    max-width: 100px;
    max-height: 87px;
    position: absolute;
    top: 0;
    left: 0;
  }

  img.needle {
    width: 48px;
    height: 12px;
    position: absolute;
    left: 10px;
    top: 44px;
    transform-origin: 42px 6px;
    transform: rotate(-45deg);
    transition: transform .3s ease;
    backface-visibility: hidden;
  }

  p {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: bold;
  }
}