.form {

  &__group {
    display: flex;
    justify-content: space-between;

    .input-group {
      flex: 1;

      &:first-child {
        margin-right: 20px;
      }
    }

    @media (max-width: 480px) {
      display: block;
    }
  }

  &__input {
    display: block;
    width: 100%;
    border: 2px solid $border-color;
    outline: 0;
    padding: 14px 20px;
    font-size: 16px;
    font-family: $ff-primary;
  }

  &--newsletter {

    input {
      display: block;
      width: 100%;
      border: 2px solid $border-color;
      outline: 0;
      padding: 14px 20px;
      font-size: 16px;
      font-family: $ff-primary;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      background-color: $color-highlight;
      color: $color-primary;
      line-height: 1.34;
      text-decoration: none;
      cursor: pointer;
      border: 0;
      outline: 0;
      width: 200px;
      font-size: 18px;
      height: 54px;
      border-radius: 30px;
      transition: background-color .3s ease;
      margin: 55px auto 0;

      &:hover {
        background-color: $color-highlight-tertiary;
      }
    }

    .msg-alert {
      padding: 20px 0 0;

      p {
        font-size: 14px;
      }
    }
  }
}

.input-group-wrap {
  display: flex;
  justify-content: space-between;

  .input-group {
    width: 48%;
  }

  @media (max-width: 480px) {
    display: block;

    .input-group {
      width: 100%;
    }
  }
}

.input-group {
  margin-bottom: 40px;

  &__label {
    display: block;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.35;

    span {
      color: $color-error;
    }
  }

  &__input {
    display: block;
    width: 100%;
    color: $color-tertiary;
    font-size: 14px;
    font-family: $ff-primary;
    border: 1px solid $border-color-tertiary;
    outline: 0;
    border-radius: 4px;
    padding: 9px 13px;
    transition: border-color .1s ease;

    &.error {
      border-color: $color-error;

      &:hover {
        border-color: $color-error;
      }
    }

    &::-webkit-input-placeholder {
      color: $input-color;
    }
    &::-moz-placeholder { 
      color: $input-color;
    }
    &:-ms-input-placeholder {
      color: $input-color;
    }
    &:-moz-placeholder { 
      color: $input-color;
    }

    &:hover {
      border-color: darken($border-color-tertiary, 30%);
    }
  }

  select.input-group__input {
    padding: 6px 13px;
  }

  &__error {
    display: block;
    color: $color-error;
    font-size: 12px;
    margin-top: 5px;
  }

  &--switch {

    input[type="checkbox"] {
      display: none;
    }
  }

  &__switch {
    width: 40px;
    height: 20px;
    border: 1px solid $border-color-tertiary;
    border-radius: 10px;
    position: relative;
    transition: background-color .3s ease, border-color .3s ease;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $border-color-tertiary;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);
      border-radius: 10px;
      transition: left .3s ease, background-color .3s;
    }

  }

  &--switch input:checked ~ .input-group__switch {
    background-color: $color-highlight;
    border-color: transparent;

    &::after {
      left: calc(100% - 20px);
      background-color: $color-highlight;
    }
  }

  &--checkbox {

    label {
      display: inline-flex;
      align-items: center;
      margin-bottom: 0;
      cursor: pointer;

      input {
        display: none;
      }

      span {
        color: $color-primary;
      }
    }
  }

  &__checkbox {
    width: 20px;
    height: 17px;
    position: relative;
    border: 1px solid $border-color-tertiary;
    border-radius: 2px;
    margin-right: 13px;
    transition: background-color .3s ease, border-color .3s ease;

    &::after {
      content: '\2713';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -42%);
      opacity: 0;
      font-size: 12px;
      transition: opacity .3s ease;
      backface-visibility: hidden;
    }
  }

  &--checkbox input:checked ~ .input-group__checkbox {
    background-color: $color-highlight;
    border-color: $color-highlight;

    &::after {
      opacity: 1;
    }
  }

  .chosen {
    position: relative;

    &__selected {
      border: 1px solid $border-color-tertiary;
      height: 36px;
      padding: 0 13px;
      border-radius: 4px;
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;

      &.error {
        border-color: $color-error;
      }

      &::after {
        top: 50%;
        right: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: #000;
        border-width: 4px;
        margin-left: -4px;
        margin-top: -2px;
      }
    }

    &__list-wrap {
      padding: 10px;
      border: 1px solid $border-color-tertiary;
      position: absolute;
      top: 100%;
      margin-top: -1px;
      z-index: 5;
      background-color: #fff;
      width: 100%;

      input {
        display: block;
        width: 100%;
        color: $color-tertiary;
        font-size: 14px;
        font-family: $ff-primary;
        border: 1px solid $border-color-tertiary;
        outline: 0;
        border-radius: 4px;
        padding: 6px 13px;
        margin-bottom: 5px;
      }

      ul {
        list-style-type: none;
        padding: 0;
        max-height: 150px;
        overflow-y: auto;

        li {
          padding: 5px 10px;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background-color: $color-highlight;
          }
        }
      }
    }
  }
}

.input-group .custom-select {
  
  &__control {
    border: 1px solid $border-color-tertiary;
    border-radius: 4px;
    min-height: 40px;
    box-shadow: 0 0 0;

    &:hover {
      border-color: darken($border-color-tertiary, 30%);
    }
  }

  &__value-container {
    outline: 0;
    padding-left: 0;
  }

  &__single-value {
    font-size: 14px;
    padding-left: 13px;
    padding-right: 13px;
  }

  &__input {
    padding-left: 13px;
    padding-right: 13px;
    font-size: 14px;
  }

  &__menu {

  }

  &__menu-list {

  }

  &__option {
    font-size: 14px;

    &:hover {
      background-color: lighten($color-highlight, 30%);
    }

    &--is-selected {
      background-color: $color-highlight;
      color: $color-primary;

      &:hover {
        background-color: $color-highlight;
      }
    }

    &--is-focused {
      background-color: lighten($color-highlight, 30%);
    }
  }
}