*,
*::after,
*::before {
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

body.modal-opened {
  overflow: hidden;
}