.c-score-step {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 46px;

  @media (max-width: 600px) {
    padding: 0 30px;
  }

  &__head {
    text-align: center;
    margin-bottom: 37px;
  }

  &__body {
    padding: 0 110px;
    flex: 1;

    .form {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;

      &__body {
        flex: 1;
      }

      &__foot {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        > * {
          margin: 0 5px;

        }

        @media (max-width: 767px) {

          .btn {
            min-width: 120px;
          }
        }

        @media (max-width: 400px) {

          .btn {
            margin: 5px;
          }
        }
      }
    }

    @media (max-width: 991px) {
      padding: 0 70px;
    }

    @media (max-width: 767px) {
      padding: 0 40px;
    }

    @media (max-width: 600px) {
      padding: 0;
    }
  }
}