.s-steps {
  padding: 75px 0 100px;
  background-color: $color-primary;

  h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 68px;
  }

  @media (max-width: 991px) {
    padding: 50px 0 75px;

    h2 {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 480px) {
    padding: 30px 0 50px;

    h2 {
      margin-bottom: 30px;
    }
  }

  &__centered {
    text-align: center;
  }

  &__bar {
    width: 117.32%;
    margin-left: -8.66%;
    display: flex;
    align-items: center;
    margin-bottom: 74px;

    div {
      height: 88px;
      width: 30%;
      display: flex;
      align-items: center;
      font-size: 32px;
      color: #fff;
      position: relative;

      &:nth-child(1) {
        background-color: $color-highlight-secondary;
        padding-left: 7.38%;
        border-radius: 44px 0 0 44px;
        z-index: 2;

        &::after {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(136, 183, 213, 0);
          border-left-color: $color-highlight-secondary;
          border-width: 44px;
          margin-top: -44px;
        }
      }
      &:nth-child(2) {
        background-color: $color-highlight-tertiary;
        color: $color-primary;
        padding-left: 80px;
        z-index: 1;

        &::after {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-left-color: $color-highlight-tertiary;
          border-width: 44px;
          margin-top: -44px;
        }
      }
      &:nth-child(3) {
        background-color: $color-highlight;
        color: $color-primary;
        border-radius: 0 44px 44px 0;
        padding-left: 80px;
        width: 40%;
      }
    }

    @media (max-width: 1350px) {
      width: 104%;
      margin-left: -2%;

      div {
        font-size: 24px;
        width: 33.33%;
        height: 60px;

        &:nth-child(1) {
          padding-left: 2%;

          &::after {
            border-width: 30px;
            margin-top: -30px;
          }
        }
        &:nth-child(2) {
          padding-left: 40px;

          &::after {
            border-width: 30px;
            margin-top: -30px;
          }
        }
        &:nth-child(3) {
          width: 33.33%;
          padding-left: 40px;
        }
      }
    }

    @media (max-width: 1200px) {
      width: 100%;
      margin-left: 0;

      div {
        &:nth-child(1) {
          padding-left: 20px;
        }
      }
    }

    @media (max-width: 991px) {
      div {
        font-size: 18px;
        height: 46px;

        &:nth-child(1) {
          padding-left: 20px;

          &::after {
            border-width: 23px;
            margin-top: -23px;
          }
        }
        &:nth-child(2) {
          &::after {
            border-width: 23px;
            margin-top: -23px;
          }
        }
      }
    }

    @media (max-width: 767px) {
      div {
        font-size: 14px;
        height: 34px;

        &:nth-child(1) {
          padding-left: 10px;

          &::after {
            border-width: 17px;
            margin-top: -17px;
          }
        }
        &:nth-child(2) {
          padding-left: 24px;

          &::after {
            border-width: 17px;
            margin-top: -17px;
          }
        }
        &:nth-child(3) {
          padding-left: 24px;
        }
      }
    }

    @media (max-width: 600px) {
      div {
        font-size: 12px;
        height: 26px;

        &:nth-child(1) {
          padding-left: 10px;

          &::after {
            border-width: 13px;
            margin-top: -13px;
          }
        }
        &:nth-child(2) {
          padding-left: 24px;

          &::after {
            border-width: 13px;
            margin-top: -13px;
          }
        }
        &:nth-child(3) {
          padding-left: 24px;
        }
      }
    }

    @media (max-width: 480px) {
      margin-bottom: 20px;
      display: block;

      div {
        width: 100%;
        height: 40px;
        justify-content: center;

        &:nth-child(1) {
          border-radius: 0;
          padding: 0 20px;

          &::after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            border-top-color: $color-highlight-secondary;
            border-width: 20px;
            margin-left: -20px;
          }
        }
        &:nth-child(2) {
          padding: 0 20px;

          &::after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            border-top-color: $color-highlight-tertiary;
            border-width: 20px;
            margin-left: -20px;
          }
        }
        &:nth-child(3) {
          border-radius: 0;
          width: 100%;
          padding: 0 20px;
        }
      }
    }
  }

  &__content {
    position: relative;

    &_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 62px;

      &-left {
        width: 350px;

        h3 {
          margin-bottom: 7px;
          color: $color-highlight-tertiary;

          &.color-2 {
            color: $color-highlight;
          }

          &.color-3 {
            color: $color-highlight-secondary;
          }
        }

        p {
          font-size: 24px;
          line-height: 1.33;
          color: #fff;
        }
      }

      &-right {
        width: 310px;

        p {
          font-size: 20px;
          line-height: 1.35;
          color: #fff;
        }
      }
    }

    &_box-center {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 268px;
      text-align: center;

      img {
        display: block;
        margin: 0 auto 71px;
      }
    }

    @media (max-width: 991px) {
      &_box {
        margin-bottom: 30px;

        &-left {
          width: 34%;

          p {
            font-size: 16px;
          }
        }
        &-right {
          width: 34%;

          p {
            font-size: 16px;
          }
        }
      }

      &_box-center {
        max-width: 100px;

        img {
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width: 480px) {
      &_box-center {
        position: static;
        transform: translateX(0);
        margin: 0 auto 10px;
        max-width: 60px;
      }

      &_box {
        margin-bottom: 30px;

        &-left {
          width: 48%;
        }
        &-right {
          width: 48%;
        }
      }
    }
  }
}
