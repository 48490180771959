.s-newsletter {
  padding: 100px 0 314px;
  background-color: $color-primary;
  position: relative;

  &__bg {
    background-image: url('../../img/bg-pattern-2.svg');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 578px;
    left: 0;
    bottom: 0;
  }

  &__box {
    max-width: 613px;
    margin: 0 auto;
    background-color: #fff;
    padding: 68px 72px;
    box-shadow: -10px 40px 20px rgba(0,0,0,0.2);
    z-index: 1;
    position: relative;

    h2 {
      text-align: center;
      margin-bottom: 16px;
      line-height: 1.35;
    }

    p {
      margin-bottom: 38px;
      text-align: center;
    }

    .u-btn-wrapper {
      margin-top: 55px;
    }
  }

  @media (max-width: 991px) {
    padding: 75px 0 250px;
  }

  @media (max-width: 767px) {
    padding: 50px 0 200px;
  }

  @media (max-width: 600px) {
    &__box {
      padding: 40px;

      p {
        margin-bottom: 20px;
      }

      .u-btn-wrapper {
        margin-top: 30px;
      }
    }

    &__bg {
      height: 400px;
    }
  }

  @media (max-width: 480px) {
    padding: 25px 0 100px;

    &__bg {
      height: 300px;
    }

    &__box {
      padding: 20px;

      .u-btn-wrapper {
        margin-top: 20px;
      }
    }
  }
}