.c-image-box {
  width: 100%;
  margin-right: 2.6%;
  padding-top: 25px;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 480px) {
    width: 70%;
    margin: 0 auto 20px;

    &:last-child {
      margin-right: auto;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.31;
    margin-bottom: 14px;
  }

  &__img-container {
    position: relative;
    padding-top: 82.2%;

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.full {
        width: 100%;
      }
    }
  }

  &:first-child {
    .c-image-box__img-container {
      img {
        max-height: 90%;
      }
    }
  }

  &--primary {
    background-color: $color-highlight-secondary;
    color: #fff;
  }

  &--secondary {
    background-color: $color-highlight-tertiary;
    color: $color-secondary;
  }

  &--tertiary {
    background-color: $color-highlight;
    color: $color-secondary;
  }
}
