body {
  font-family: $ff-primary;
  font-size: 16px;
}

h1 {
  font-family: $ff-header;
  font-size: 64px;
  font-weight: normal;
  line-height: 1.33;
}

h2 {
  font-family: $ff-header;
  font-size: 32px;
  line-height: 1.33;
  font-weight: normal;
}

h3 {
  font-family: $ff-header;
  font-size: 32px;
  line-height: 1.33;
  font-weight: normal;
  letter-spacing: -0.8px;
}

h4 {
  font-family: $ff-header;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.36;
}

p {
  font-size: 18px;
  line-height: 1.33;
}

@media (max-width: 991px) {
  h1 {
    font-size: 50px;
  }
  h3 {
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 40px;
  }
}
